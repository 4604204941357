import React, { useState } from 'react';
import { Button, Spinner } from '@frontend/web-components';
import styles from '../Home.module.css';

import { FIELD_LABELS, STATIC_TEXT } from '@frontend/common';
import {
  useUserInterestedMutation,
  checkIfValidApiResponse,
  useGetLocationsQuery,
  useGetNoticeTypesQuery,
  MasterItem,
} from '@frontend/redux';

type Props = {
  updateUserInfo: () => void;
};

interface Images {
  [key: string]: string;
}

const images: Images = {
  mumbai: require('assets/src/images/mumbai.png'),
  pune: require('assets/src/images/pune.png'),
  thane: require('assets/src/images/thane.png'),
  raigad: require('assets/src/images/raigad.png'),
  palghar: require('assets/src/images/palghar.png'),
  'navi-mumbai': require('assets/src/images/navi_mumbai.png'),
};

export default function (props: Props) {
  const { updateUserInfo } = props;

  const [userInterested, { isLoading: isPostingUserInterests }] = useUserInterestedMutation();
  const {
    data: locations,
    isLoading: isGettingLocations,
    error: locationsError,
  } = useGetLocationsQuery();
  const {
    data: noticeTypes,
    isLoading: isGettingNoticeTypes,
    error: noticeTypesError,
  } = useGetNoticeTypesQuery();

  const [selectedNoticeTypes, setSelectedNoticeTypes] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    const payload = {
      notice_types: selectedNoticeTypes,
      locations: selectedLocations,
    };
    const res = await checkIfValidApiResponse(userInterested, payload);
    if (res.isSuccess) {
      updateUserInfo();
    } else {
      const message = res?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const onSelectNoticeType = (noticeType: string) => {
    if (selectedNoticeTypes.includes(noticeType)) {
      setSelectedNoticeTypes(selectedNoticeTypes.filter((type) => type !== noticeType));
    } else {
      setSelectedNoticeTypes([...selectedNoticeTypes, noticeType]);
    }
  };

  const onSelectLocation = (location: string) => {
    if (selectedLocations.includes(location)) {
      setSelectedLocations(selectedLocations.filter((loc) => loc !== location));
    } else {
      setSelectedLocations([...selectedLocations, location]);
    }
  };

  const renderNoticeTypeItem = (item: MasterItem, idx: React.Key) => {
    const selected = selectedNoticeTypes.includes(item.id);

    return (
      <button
        className={`${styles.onbNoticeTypeOption} ${selected ? styles.selected : ''}`}
        key={idx}
        onClick={() => onSelectNoticeType(item.id)}>
        <p className={selected ? 'text-white' : 'text-black'}>{item.name}</p>
      </button>
    );
  };

  const renderCityItem = (item: MasterItem, idx: React.Key) => {
    const selected = selectedLocations.includes(item.id);

    return (
      <button className={styles.onbCityOption} key={idx} onClick={() => onSelectLocation(item.id)}>
        {!selected && <div className={styles.onbImageMask} />}
        <img src={images[item.slug]} alt={item.name} />
        <p className={selected ? 'text-black' : 'text-gray-400'}>{item.name}</p>
      </button>
    );
  };

  if (isGettingLocations || isGettingNoticeTypes) {
    return <Spinner modal />;
  }

  if (locationsError || noticeTypesError) {
    return (
      <div className="error-text">
        <p>{STATIC_TEXT.GENERIC_ERROR}</p>
      </div>
    );
  }

  return (
    <div className={styles.pageLayout}>
      <div className={styles.centerContainer}>
        <h3>{STATIC_TEXT.SELECT_NOTICE_TYPES}</h3>
        <div className={styles.onbNoticeTypeContainer}>
          {noticeTypes &&
            noticeTypes.data.map((noticeType, idx) => renderNoticeTypeItem(noticeType, idx))}
        </div>
        <h3>{STATIC_TEXT.PREFERRED_LOCATIONS}</h3>
        <div className="flex flex-wrap justify-center">
          {locations && locations.data.map((location, idx) => renderCityItem(location, idx))}
        </div>
        <Button
          disabled={selectedLocations.length === 0 || selectedNoticeTypes.length === 0}
          loading={isPostingUserInterests}
          onClick={handleSubmit}>
          {FIELD_LABELS.NEXT}
        </Button>
      </div>
    </div>
  );
}
