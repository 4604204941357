import { CustomerInfo } from 'react-native-purchases';

// Subscription devices
export enum SubcriptionDeviceName {
  ANDROID = 'android',
  IOS = 'ios',
  MANUAL = 'manual',
  WEB = 'web',
}

export interface SubscriptionDevice {
  id: string;
  name: SubcriptionDeviceName;
}

// Subscription Statuses
export enum SubscriptionStatusName {
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  FAILED = 'failed',
  INITIATED = 'initiated',
}

export interface SubscriptionStatus {
  id: string;
  name: SubscriptionStatusName;
}

// Subscription Credits
export interface SubscriptionCredits {
  amount: string;
  credit_type_slug: string;
  id: string;
  quantity: number;
  type: string;
}

export interface SubscriptionParamsResponse {
  credits: SubscriptionCredits[];
  devices: SubscriptionDevice[];
  status: SubscriptionStatus[];
}

// Stripe Request and Response
export interface AndroidIntentRequest {
  amount: number;
}

export interface StripeIntentResponse {
  data: {
    customer: string;
    ephemeralKey: string;
    paymentIntent: string;
    publishableKey: string;
  };
}

export interface RazorpayIntentResponse {
  data: {
    amount: number;
    amount_due: number;
    amount_paid: number;
    attempts: number;
    created_at: number;
    currency: string;
    entity: string;
    id: string;
    notes: {
      customer_id: string;
    };
    offer_id: string | null;
    receipt: null | string;
    status: string;
  };
}

// Payment Status Request
export interface PostPaymentStatusRequest {
  payment_device_id: string;
  payment_method: string;
  payment_response?: Record<string, string> | CustomerInfo;
  payment_status_id: string;
  total_alert: number;
  total_amount: number;
  total_notice: number;
}

export interface PostSubscriptionStatusRequest {
  payment_device_id: string;
  payment_method: string;
  payment_response?: Record<string, string>;
  payment_status_id: string;
  plan_id: string;
}

export interface UpdatePaymentStatusRequest extends PostPaymentStatusRequest {
  paymentId: string;
}

export interface CustomPackageRequest {
  callback: boolean;
  requirement: string;
}

export interface CreditStatusResponse {
  data: {
    alert_credits: {
      remaning: number;
      remaning_amount: number;
      total: number;
    };
    notice_credits: {
      remaning: number;
      remaning_amount: number;
      total: number;
    };
    subscription_plan?: {
      cancel_date: string | null;
      from: string;
      next_billing_date: string;
      payment_status?: string | null;
      plan_end_at: string;
      to: string;
    };
  };
}

export interface TransactionsResponse {
  data: {
    [key: string]: {
      credit_type: 'alert' | 'notice-subscription';
      date_of_purchase: string;
      expire_month: number;
      gst_amount: number;
      gst_percentage: string;
      id: string;
      ios_platform_fee: number;
      payment_device: string;
      payment_method_type: string;
      payment_response: string;
      payment_status: string;
      subtotal: number;
      total_amount: number;
      total_credits: number;
    }[];
  };
}

export interface PriceItem {
  amount: string;
  credit_type_id: string;
  credit_type_name: string;
  credit_type_slug: string;
  description: string | null;
  discount_percentage: number;
  gst_percentage: string;
  id: string;
  max_quantity: number;
  min_quantity: number;
  quantity: number;
  type: string;
}

export interface PriceResponse {
  data: PriceItem[];
}
