import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import useRazorpay from 'react-razorpay';
import toast from 'react-hot-toast';

import { Button, CenterModal, Spinner, PaymentSuccess } from '@frontend/web-components';
import {
  checkIfValidApiResponse,
  RazorpayIntentResponse,
  SubscriptionStatusName,
  useGetRzpSubsciptionsPlansMutation,
  usePostRazorpayIntentSubscriptionMutation,
  usePostSubscriptionStatusMutation,
  useGetPaymentParamsMutation,
  SubscriptionDevice,
  SubscriptionStatus,
  usePutSubscriptionStatusMutation,
  useCancelRazorpaySubscriptionMutation,
  CreditStatusResponse,
} from '@frontend/redux';
import { ColorPalette, CONSTANTS, STATIC_TEXT } from '@frontend/common';

const rzp_key = process.env.NX_BASE_RAZORPAY_KEY || '';

type Props = {
  creditStatus?: CreditStatusResponse;
  onClose: () => void;
  open: boolean;
  refetchCreditStatus: () => void;
};

const InfinitePlan = ({ open, onClose, creditStatus, refetchCreditStatus }: Props) => {
  const [Razorpay] = useRazorpay();

  const [fetchRazorplayPlans, { isLoading: isFetchingPlans }] =
    useGetRzpSubsciptionsPlansMutation();
  const [postRazorpaySubscriptionIntent] = usePostRazorpayIntentSubscriptionMutation();
  const [postSubscriptionStatus] = usePostSubscriptionStatusMutation();
  const [putSubscriptionStatus] = usePutSubscriptionStatusMutation();
  const [getPaymentParams] = useGetPaymentParamsMutation();
  const [cancelRazorpaySubscription] = useCancelRazorpaySubscriptionMutation();

  const isAlreadySubscribed = creditStatus?.data.subscription_plan?.payment_status === 'paid';
  const isSubscriptionPending = creditStatus?.data.subscription_plan?.payment_status === 'pending';
  const isNotSubscribed = !creditStatus?.data.subscription_plan?.payment_status;
  const isSubscriptionCancelled =
    creditStatus?.data.subscription_plan?.payment_status === 'cancelled';
  const cancelDate = creditStatus?.data.subscription_plan?.cancel_date;
  const nextBillingDate = creditStatus?.data.subscription_plan?.next_billing_date;
  const isBillingDateValid =
    nextBillingDate && moment(nextBillingDate, 'DD-MM-YYYY').isSameOrAfter(moment());

  const currentPaymentIdRef = useRef<string | null>(null);

  const [initialLoading, setInitialLoading] = useState(true);
  const [planId, setPlanId] = useState();
  const [subscriptionDevices, setSubscriptionDevices] = useState<SubscriptionDevice[] | null>(null);
  const [subscriptionStatuses, setSubscriptionStatuses] = useState<SubscriptionStatus[] | null>(
    null
  );
  const [updateStatus, setUpdateStatus] = useState(SubscriptionStatusName.INITIATED);
  const [updateMessage, setUpdateMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isPaymentProcessing, setProcessingPayment] = useState(false);

  useEffect(() => {
    getRazorplayPlans();
    fetchPaymentParams();
    setInitialLoading(false);
  }, []);

  useEffect(() => {
    if (updateStatus === SubscriptionStatusName.COMPLETED) {
      refetchCreditStatus();
      setOpenSuccessModal(true);
      setProcessingPayment(false);
      onClose();
    } else if (
      [SubscriptionStatusName.FAILED, SubscriptionStatusName.CANCELLED].includes(updateStatus)
    ) {
      toast.error(updateMessage);
      setProcessingPayment(false);
    }
  }, [updateStatus]);

  const fetchPaymentParams = async () => {
    const res = await checkIfValidApiResponse(getPaymentParams, undefined);
    if (res.data && res.data.data && res.isSuccess) {
      setSubscriptionDevices(res.data.data.devices);
      setSubscriptionStatuses(res.data.data.status);
    }
  };

  const getRazorplayPlans = async () => {
    const res = await checkIfValidApiResponse(fetchRazorplayPlans, undefined);
    if (res.data && res.data.data && res.isSuccess) {
      const infinitePlan = res.data.data.find((plan: any) => plan.name === '99 Monthly Plan');
      setPlanId(infinitePlan.plan_id);
    } else {
      const message = res.data.data?.message || STATIC_TEXT.GENERIC_ERROR;
      toast.error(message);
    }
  };

  const cancelSubscription = async () => {
    const res = await checkIfValidApiResponse(cancelRazorpaySubscription, { planId });
    if (res.data && res.data.data && res.isSuccess) {
      refetchCreditStatus();
      const message = res.data.data?.message || 'Subscription Cancelled';
      toast.success(message);
      setProcessingPayment(false);
      onClose();
    } else {
      const message = res.data.data?.message || STATIC_TEXT.GENERIC_ERROR;
      toast.error(message);
    }
    setProcessingPayment(false);
  };

  const handlePostSubscriptionStatus = async (
    payment_method: string,
    status: SubscriptionStatusName,
    response?: Record<string, string>
  ) => {
    const platform = 'web';

    const obj = {
      payment_method,
      plan_id: planId,
      payment_device_id:
        subscriptionDevices?.find((item) => item.name.toLowerCase() === platform)?.id || '',
      payment_status_id: subscriptionStatuses?.find((item) => item.name === status)?.id || '',
      payment_response: response,
    };

    const currentPaymentId = currentPaymentIdRef.current;
    const updateFunction = currentPaymentId ? putSubscriptionStatus : postSubscriptionStatus;

    const res = await checkIfValidApiResponse(updateFunction, {
      paymentId: currentPaymentId,
      ...obj,
    });
    if (res.isSuccess) {
      if (!currentPaymentId) {
        currentPaymentIdRef.current = res.data.data?.id;
      }
      setUpdateMessage(res.data.data?.message);
      setUpdateStatus(status);
    } else {
      const message = res.data.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setUpdateMessage(message);
      setUpdateStatus(SubscriptionStatusName.FAILED);
    }
  };

  const initiateRazorpayPayment = async () => {
    if (!planId) {
      return;
    }

    const res = await checkIfValidApiResponse(postRazorpaySubscriptionIntent, { planId });
    if (res.data && res.data.data && res.isSuccess) {
      const data = res.data as RazorpayIntentResponse;
      await handlePostSubscriptionStatus('razorpay', SubscriptionStatusName.INITIATED, undefined);

      const options = {
        description: 'Maha eNotice Notice - Infinite Plan',
        currency: 'INR',
        key: rzp_key,
        name: 'Maha eNotice',
        theme: { color: ColorPalette.PRIMARY },
        subscription_id: data.data.id,
        handler: async (res: {
          razorpay_order_id: string;
          razorpay_payment_id: string;
          razorpay_signature: string;
        }) => {
          await handlePostSubscriptionStatus('razorpay', SubscriptionStatusName.COMPLETED, res);
        },
        modal: {
          ondismiss: async () => {
            await handlePostSubscriptionStatus('razorpay', SubscriptionStatusName.CANCELLED, {
              reason: 'cancelled',
            });
          },
        },
      };

      // @ts-ignore
      const rzpay = new Razorpay(options);
      rzpay.on('payment.failed', async function (response: any) {
        await handlePostSubscriptionStatus('razorpay', SubscriptionStatusName.CANCELLED, response);
      });
      rzpay.open();
    } else {
      const message = res.data.data?.message || STATIC_TEXT.GENERIC_ERROR;
      toast.error(message);
      setProcessingPayment(false);
    }
  };

  const handleButtonPress = async () => {
    setProcessingPayment(true);
    if (isAlreadySubscribed) {
      await cancelSubscription();
    } else {
      await initiateRazorpayPayment();
    }
  };

  const renderStartAutoPay = () => {
    return (
      <div className="p-6 flex flex-col justify-center items-center">
        <div className="flex justify-center items-center">
          <img src={require('assets/src/images/infinite_plan.png')} alt="" />
        </div>
        <h2 className="py-4 text-center">
          Get access to unlimited notices for just ₹ {CONSTANTS.INFINITE_PLAN_AMOUNT} / month
        </h2>
        <p className="text-sm mt-4">
          By continuing, you agree to our{' '}
          <a
            href={CONSTANTS.TC_URL}
            className="underline"
            target="_blank"
            rel="noopener noreferrer">
            {STATIC_TEXT.TERMS_AND_CONDITIONS}
          </a>{' '}
          and that you are over 18. MahaeNotice will automatically continue your membership and
          charge the monthly membership fee (currently ₹99 + taxes) to your payment method until you
          cancel. You may cancel at any time to avoid future charges.
        </p>
        <Button onClick={handleButtonPress} customClasses="w-40 mt-4" loading={isPaymentProcessing}>
          Join Plan
        </Button>
      </div>
    );
  };

  const renderCancelAutoPay = () => {
    return (
      <div className="p-6 flex flex-col justify-center items-center">
        <div className="flex justify-center items-center">
          <img src={require('assets/src/images/infinite_plan.png')} alt="" />
        </div>
        <p className="text-sm mt-4">Are you sure you want to cancel the Infinite Plan?</p>
        <div className="flex">
          <Button variant="outline" customClasses="w-40 mt-4 mr-4" onClick={onClose}>
            Keep it
          </Button>
          <Button
            onClick={handleButtonPress}
            customClasses="w-40 mt-4"
            loading={isPaymentProcessing}>
            Cancel Plan
          </Button>
        </div>
      </div>
    );
  };

  const renderPendingSubscription = () => {
    return (
      <div className="p-6 flex flex-col justify-center items-center">
        <div className="flex justify-center items-center">
          <img src={require('assets/src/images/infinite_plan.png')} alt="" />
        </div>
        <h2 className="py-4 text-center">Status: Pending</h2>
        <p className="text-sm mt-4">
          We're fetching subscription details for your payment made. Kindly recheck this section
          post 5-10 mins to see the update.
        </p>
      </div>
    );
  };

  const renderSubscriptionIsCancelled = () => {
    return (
      <div className="p-6 flex flex-col justify-center items-center">
        <div className="flex justify-center items-center">
          <img src={require('assets/src/images/infinite_plan.png')} alt="" />
        </div>
        <h2 className="py-4 text-center">{`Your membership to Infinite Plan stands cancelled as on ${cancelDate}`}</h2>
        <p className="text-sm mt-4">
          {`You can still view unlimited notices till ${nextBillingDate}.`}
        </p>
      </div>
    );
  };

  const renderContent = () => {
    if (isFetchingPlans || initialLoading) {
      return (
        <div className="p-6 flex flex-col justify-center items-center">
          <Spinner />
        </div>
      );
    }

    if (!rzp_key) {
      return (
        <div className="p-6 flex flex-col justify-center items-center">
          <p>Something went wrong. Contact admin.</p>
        </div>
      );
    }

    if (isAlreadySubscribed) {
      return renderCancelAutoPay();
    }

    if (isSubscriptionPending) {
      return renderPendingSubscription();
    }

    if (isNotSubscribed) {
      return renderStartAutoPay();
    }

    if (isSubscriptionCancelled) {
      if (isBillingDateValid) {
        return renderSubscriptionIsCancelled();
      } else {
        return renderStartAutoPay();
      }
    }
  };

  return (
    <>
      <CenterModal visible={open} onClose={onClose} renderContent={renderContent} />{' '}
      <PaymentSuccess
        visible={openSuccessModal}
        closeModal={() => setOpenSuccessModal(false)}
        message={updateMessage}
      />
    </>
  );
};

export default InfinitePlan;
