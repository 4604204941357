import React, { useState } from 'react';
import moment from 'moment';

import { Button } from '@frontend/web-components';
import { useGetCreditStatusQuery } from '@frontend/redux';

import InfinitePlan from './InfinitePlan';
import { CONSTANTS } from '@frontend/common';

const items = [
  {
    img: require('assets/src/images/sub-1.png'),
    title: 'Unlimited Notices',
    desc: 'Search from over 11 lakhs notices from all over India',
  },
  {
    img: require('assets/src/images/sub-2.png'),
    title: 'Zero Hassles',
    desc: 'No need to buy credits every time to view notices',
  },
  {
    img: require('assets/src/images/sub-3.png'),
    title: 'No commitments',
    desc: 'Cancel any time you want',
  },
];

function PromotionalBanner() {
  const {
    data: creditStatus,
    isFetching: isFetchingCreditStatus,
    refetch: refetchCreditStatus,
  } = useGetCreditStatusQuery();

  const isAlreadySubscribed = creditStatus?.data.subscription_plan?.payment_status === 'paid';
  const isSubscriptionPending = creditStatus?.data.subscription_plan?.payment_status === 'pending';
  const isNotSubscribed = !creditStatus?.data.subscription_plan?.payment_status;
  const isSubscriptionCancelled =
    creditStatus?.data.subscription_plan?.payment_status === 'cancelled';
  const fromDate = creditStatus?.data.subscription_plan?.from;
  const cancelDate = creditStatus?.data.subscription_plan?.cancel_date;
  const nextBillingDate = creditStatus?.data.subscription_plan?.next_billing_date;
  const isBillingDateValid =
    nextBillingDate && moment(nextBillingDate, 'DD-MM-YYYY').isSameOrAfter(moment());

  const [openInfinitePlanModal, setInfinitePlanModal] = useState(false);

  const renderJoinMessage = () => {
    return (
      <div className="py-12 px-4 flex flex-col items-center bg-PRIMARY_TINT rounded justify-center text-center">
        <span className="text-2xl font-bold">₹{CONSTANTS.INFINITE_PLAN_AMOUNT}</span>
        <span className=" text-lg">per month</span>
        <Button
          onClick={() => setInfinitePlanModal(true)}
          customClasses="w-40 mt-4"
          loading={isFetchingCreditStatus}>
          Join
        </Button>
      </div>
    );
  };

  const renderCancelMessage = () => {
    return (
      <div className="py-12 px-4 flex flex-col items-center bg-PRIMARY_TINT rounded justify-center text-center">
        <span className="text-2xl">You are already subscribed to our Infinite Plan.</span>
        <span className=" text-sm mt-2">
          {' '}
          {`Member Since ${fromDate}.\nPlan Renews on ${nextBillingDate}`}
        </span>
        <Button
          onClick={() => setInfinitePlanModal(true)}
          customClasses="w-40 mt-4"
          loading={isFetchingCreditStatus}>
          Manage
        </Button>
      </div>
    );
  };

  const renderPendingMessage = () => {
    return (
      <div className="py-12 px-4 flex flex-col items-center bg-PRIMARY_TINT rounded justify-center text-center">
        <span className="text-2xl">Status: Pending</span>
        <span className=" text-sm mt-2">
          We're fetching subscription details for your payment made. Kindly recheck this section
          post 5-10 mins to see the update.
        </span>
      </div>
    );
  };

  const renderCancelledMessage = () => {
    return (
      <div className="py-12 px-4 flex flex-col items-center bg-PRIMARY_TINT rounded justify-center text-center">
        <span className="text-2xl">{`Your membership to Infinite Plan stands cancelled as on ${cancelDate}`}</span>
        {isBillingDateValid ? (
          <span className=" text-sm mt-2">
            {`You can still view unlimited notices till ${nextBillingDate}.`}
          </span>
        ) : (
          <Button
            onClick={() => setInfinitePlanModal(true)}
            customClasses="w-40 mt-4"
            loading={isFetchingCreditStatus}>
            Restart
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex mt-12 justify-center items-center">
        <img src={require('assets/src/images/infinite_plan.png')} alt="" />
      </div>
      <div className="rounded w-full relative border mt-12 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col justify-center px-4 md:px-20 mt-8">
            {items.map((item, index) => (
              <div key={index} className="flex items-center my-4">
                <img src={item.img} alt="" />
                <div className="flex flex-col ml-4">
                  <span className="text-lg font-bold">{item.title}</span>
                  <span className=" text-sm">{item.desc}</span>
                </div>
              </div>
            ))}
          </div>
          {isAlreadySubscribed && renderCancelMessage()}
          {isNotSubscribed && renderJoinMessage()}
          {isSubscriptionPending && renderPendingMessage()}
          {isSubscriptionCancelled && renderCancelledMessage()}
        </div>
        <div className="px-6 py-2 bg-PRIMARY absolute top-0 left-20 rounded-b-md">
          <span className="text-white">Infinite benefits</span>
        </div>
      </div>
      {openInfinitePlanModal && (
        <InfinitePlan
          open={openInfinitePlanModal}
          onClose={() => setInfinitePlanModal(false)}
          creditStatus={creditStatus}
          refetchCreditStatus={refetchCreditStatus}
        />
      )}
    </>
  );
}

export default PromotionalBanner;
