type Filters = Record<string, any>;
type TransformedFilters = Record<string, string | number | boolean>;

export const transformFiltersToStringParams = (filters: Filters): TransformedFilters => {
  const transformed: TransformedFilters = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        transformed[key] = value.join(',');
      }
    } else if (value !== undefined && value !== null) {
      transformed[key] = value;
    }
  });

  return transformed;
};
