export const CONSTANTS = {
    CONTACT_EMAIL: 'support@mahaenotice.com',
    OTP_LENGTH: 4,
    RESEND_OTP_TIME: 30,
    VIEW_CREDITS_EXPIRY: 'Lifetime',
    YES: 'Yes',
    NO: 'No',
    TC_URL: 'https://www.mahaenotice.com/terms-of-use/',
    PRIVACY_URL: 'https://www.mahaenotice.com/privacy-policy/',
    CONTACT_ADDRESS: 'Flat No. 4, 1202/3/19 Chandrashil Apartment, Deccan Gymkhana Pune City Pune, Maharashtra - 411004, India',
    CONTACT_PHONE: '(+91) 9767967252',
    ABOUT_URL: 'https://www.mahaenotice.com/about-us/',
    CONTACT_URL: 'https://www.mahaenotice.com/#footer',
    ANDROID_PACKAGE_NAME: 'com.digi9notice.app',
    IOS_CREDITS_IDENTIFIER: 'Credits',
    IOS_INFINITE_IDENTIFIER: 'com.mahaenotice.infiniteplan',
    INFINITE_PLAN_AMOUNT: 99,
    IOS_STORE_LINK: 'https://apps.apple.com/app/mahaenotice/id6504361708',
    GOOGLE_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.mahaenotice.app'
}

export const NOTICE_HIDE_FIELDS = [
    'id',
    'image',
    'property_type',
    'added_on',
    'is_bookmarked',
    'is_notice_viewed',
    'viewed_on',
    'is_purchased',
    'purchased_on',
    'payment_info',
    'hide_notice_data',
    'source',
    'updated_at',
    'created_at',
    'notify'
];

export const PROPERTY_FIELD_LABEL_MAPPINGS = {
    STATE: 'state',
    DISTRICT: 'city',
    TALUKA: 'taluka',
    VILLAGE: 'village',
    TYPE_OF_PROPERTY: 'property_type',
    OTHER_DETAILS: 'others',
};

export const PROPERTY_SELECT_FIELDS = ['TYPE_OF_PROPERTY', 'STATE', 'DISTRICT', 'VILLAGE'];
export const PROPERTY_INPUT_FIELDS = [
    'survey_name',
    'glr_survey_name',
    'hisaa_number',
    'gat_number',
    'cts_number',
    'zone_number',
    'sector_number',
    'property_number',
    'tp_number',
    'fp_number',
    'plot_number',
    'unit_number',
    'block_number',
    'building_number',
    'building_name',
    'society_name',
    'owner_name',
    'floor_number',
    'survey_hissa_number',
    'gat_hissa_number',
    'cts_hissa_number',
    'fp_hissa_number',
    'society_name_value',
    'patwari_halka',
    'wing_number',
];

export const PROPERTY_HIDE_FIELDS = [
    'id',
    'user_id',
    'image',
    'alert_count',
    'created_at',
    'deleted_at',
    'notify',
    'system_id',
    'updated_at',
    'property_id',
    'expiry_date',
    'is_alert_expire'
  ];