import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, CustomInput, CustomSelect, Spinner } from '@frontend/web-components';
import {
  FIELD_LABELS,
  FIELD_PLACEHOLDERS,
  PROPERTY_FIELD_LABEL_MAPPINGS,
  SearchScreens,
} from '@frontend/common';
import {
  useGetLocationsQuery,
  useLazyGetStatesQQuery,
  useLazyGetPropertyTypesQQuery,
  useLazyGetVillagesByCityQuery,
  MasterItem,
} from '@frontend/redux';
import { createQueryParamString } from '@frontend/utils-helpers';
import { useNavigate } from 'react-router-dom';

const PROPERTY_SELECT_FIELDS = ['STATE', 'DISTRICT', 'TALUKA', 'VILLAGE', 'TYPE_OF_PROPERTY'];
export const PROPERTY_INPUT_FIELDS = [
  'survey_name',
  'glr_survey_name',
  'hisaa_number',
  'gat_number',
  'cts_number',
  'zone_number',
  'sector_number',
  'property_number',
  'tp_number',
  'fp_number',
  'plot_number',
  'unit_number',
  'block_number',
  'building_number',
  'building_name',
  'society_name',
  'wing_number',
  'other',
];

export default function AdvancedSearch() {
  const navigate = useNavigate();

  const { control, getValues, watch, setValue } = useForm({});

  // Master APIs
  const [fetchPropertyTypes, { data: propertyTypes, isLoading: isGettingPropertyTypes }] =
    useLazyGetPropertyTypesQQuery();
  const [fetchStates, { data: states, isLoading: isGettingStates }] = useLazyGetStatesQQuery();
  const [fetchVillages, { data: villages, isLoading: isGettingVillages }] =
    useLazyGetVillagesByCityQuery();
  const { data: locations, isLoading: isGettingLocations } = useGetLocationsQuery();

  useEffect(() => {
    fetchPropertyTypes({});
    fetchStates({});
  }, []);

  useEffect(() => {
    if (states) {
      setValue('STATE', states.data[0]);
    }
  }, [states]);

  // Watch all form fields
  const watchedValues = watch();

  // Determine if at least one field is filled
  const hasAtLeastOneField = useMemo(() => {
    return Object.values(watchedValues).some(
      (value) => value !== undefined && value !== null && value !== ''
    );
  }, [watchedValues]);

  const goToSearch = () => {
    const currentValues = getValues();

    // Remove fields with no values (undefined, null, or empty strings)
    const filteredValues = Object.fromEntries(
      Object.entries(currentValues)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => {
          // If the field is in OBJECT_FIELDS and the value is an object with a 'name' property
          if (PROPERTY_SELECT_FIELDS.includes(key) && typeof value === 'object' && value.name) {
            const field =
              PROPERTY_FIELD_LABEL_MAPPINGS[key as keyof typeof PROPERTY_FIELD_LABEL_MAPPINGS];
            return [field, value.name];
          }
          return [key, value];
        })
    );

    navigateToSearch(filteredValues);
  };

  const navigateToSearch = (filteredParams: Record<string, any>) => {
    const queryParams = createQueryParamString(filteredParams);
    navigate(`${SearchScreens.SEARCH}?${queryParams}`);
  };

  const getFieldName = (field: string) => {
    let name = field.replace(/_/g, ' ').toUpperCase();
    if (field === 'hisaa_number') {
      name = 'Hissa Number';
    } else if (field === 'survey_name') {
      name = 'Survey Number';
    } else if (field === 'glr_survey_name') {
      name = 'GLR Survey Number';
    }
    return name;
  };

  const getOptions = (field: string) => {
    switch (field) {
      case 'TYPE_OF_PROPERTY':
        return propertyTypes?.data || [];
      case 'STATE':
        return states?.data || [];
      case 'DISTRICT':
        return locations?.data || [];
      case 'VILLAGE':
        return villages?.data || [];
      default:
        return [];
    }
  };

  const getLoadingState = (field: string) => {
    switch (field) {
      case 'TYPE_OF_PROPERTY':
        return isGettingPropertyTypes;
      case 'STATE':
        return isGettingStates;
      case 'DISTRICT':
        return isGettingLocations;
      case 'VILLAGE':
        return isGettingVillages;
      default:
        return false;
    }
  };

  const handleOnSelect = (
    field: string,
    value: Partial<MasterItem> | null,
    onChange: (value: Partial<MasterItem> | null) => void
  ) => {
    const currentValues = getValues();
    const currValue = currentValues[field];
    const isValueSame = currValue && value ? currValue.id === value.id : currValue === value;

    if (isValueSame) {
      return;
    }

    // Updating the selected field
    onChange(value);

    // If DISTRICT is updated, clear VILLAGE
    if (field === 'DISTRICT') {
      setValue('VILLAGE', null);

      if (value) {
        fetchVillages({ city: value.name });
      }
    }

    if (field === 'VILLAGE') {
      if (value) {
        if (value.taluka_id) {
          const taluka = {
            id: value?.taluka_id,
            name: value?.taluka,
            slug: '',
          };
          setValue('TALUKA', taluka);
        }
      } else {
        setValue('TALUKA', null);
      }
    }
  };

  const renderSelectFields = () => {
    return (
      <div className="space-y-4">
        {PROPERTY_SELECT_FIELDS.map((field) => (
          <Controller
            key={field}
            control={control}
            name={field}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <div className="mb-2">
                <CustomSelect
                  fieldName={FIELD_LABELS[field]}
                  placeholder={FIELD_PLACEHOLDERS.GENERIC_TYPE_SELECT}
                  value={value}
                  onSelectItem={(val) => handleOnSelect(field, val, onChange)}
                  options={getOptions(field)}
                  error={error ? error.message : undefined}
                  loading={getLoadingState(field)}
                  disabled={field === 'TALUKA' || field === 'STATE'}
                />
              </div>
            )}
          />
        ))}
      </div>
    );
  };

  const renderInputFields = () => {
    return (
      <div>
        {PROPERTY_INPUT_FIELDS.map((field) => (
          <Controller
            key={field}
            control={control}
            name={field}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <div className="mb-2">
                <CustomInput
                  fieldName={getFieldName(field)}
                  placeholder={FIELD_PLACEHOLDERS.GENERIC_INPUT}
                  onChangeText={(value) => onChange(value)}
                  value={value || ''}
                  error={error ? error.message : undefined}
                />
              </div>
            )}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[500px] relative px-6 py-4 overflow-y-auto">
      <div>
        <h6 className="font-semibold mb-4">Advanced Search</h6>
      </div>
      {renderSelectFields()}
      <hr className="border border-black my-4 mx-4 flex flex-grow" />
      {renderInputFields()}
      <Button onClick={goToSearch} customClasses="mt-4" disabled={!hasAtLeastOneField}>
        {FIELD_LABELS.SEARCH}
      </Button>
    </div>
  );
}
