export const STATIC_TEXT = {
  app_version: '0.0.1',
  APP_NAME: 'Maha e-Notice',
  INDIA_CODE: '🇮🇳 (+91)',
  screens: {
    subscription: {
      title: 'Our Plans',
      packages: {
        alert: {
          title: 'Alert credit',
          desc: 'You will receive a notification when there is an update regarding your property.',
        },
        notice: {
          title: 'View credit',
          desc: 'You can access and view the notice when it is posted about your property.',
        },
        custom: {
          title: 'or Customize your package',
          desc: 'Request a call back',
          additionalDesc: '(Our service will call you in next 24 - 48 hrs)',
          placeholder: 'Type in your requirements',
          button: 'Go',
        },
      },
      buy: 'Buy',
      singleCredit: '1 Credit',
      doubleCredit: '2 Credits',
      subtotal: 'Total: ₹{total}',
      eachItem: 'Every Credit is ₹{amount}',
    },
  },
  appUpdate: {
    title: 'Get more with the new update',
    desc: `We've enhanced the app, and added some new and exciting features!`,
    button: 'Download',
  },
  OTP_NOT_RECEIVED: `Haven't received an SMS?`,
  RESEND_OTP: 'Send again',
  AGREE_OUR_TC: `By continuing, you agree to our`,
  TERMS_AND_CONDITIONS: `Terms & Conditions`,
  PRIVACY_POLICY: 'Privacy Policy',
  CONTACT_OUR_EMAIL: `For any issue/query please email`,
  GENERIC_ERROR: 'Something went wrong. Please try again later.',
  GOVT_ID_MATCH: 'Make sure it matches with your government id',
  TELL_US: 'Tell us a little about yourself',
  SELECT_NOTICE_TYPES: `Choose the types of notices you're interested in:`,
  PREFERRED_LOCATIONS: 'Choose your preferred location:',
  CITIES_AVAILABLE:
    'Currently we are available for Mumbai, Thane, Raigad, Pune & Palghar district only.',
  REGISTER_ACCOUNT: 'Register for a new account',
  WHATSAPP_UPDATE: 'Receive update on WhatsApp',
  ENTER_MOBILE_NUMBER: 'Enter mobile number',
  POST_NOTICE_TEXT: `Get Timely Alerts. Prevent fraudulent activity on your property before it’s too late`,
  POST_NOTICE: 'Post Property For Free',
  TAGLINE: 'Protect your property.',
  LOGOUT_PROMPT: 'Are you sure you want to logout?',
  NO_NOTICES_FOUND: 'No notices found',
  SHOWING_NOTICES: 'Showing notices for',
  SHOWING_ALL_NOTICES: 'Showing all notices',
  NO_DATA_AVAILABLE: 'No data available',
  BUY_CREDIT: 'Buy 1 credit',
  USE_CREDIT: 'Use 1 credit',
  MY_PROPERTIES: 'My Properties',
  NO_PROPERTIES_FOUND: 'No properties found',
  NO_DATA_FOUND: 'No data found',
  FILTER_DETAILS_HELPER:
    'Type all your property details for example Survey no., Plot no., GAT no., CTS no. etc',
  ADD_ALERT: 'Add alert for your property',
  NOTIFY_ALERT: 'We will notify you immediately when a notice about your property is uploaded.',
  VIEW_ALERTS: 'View Alerts',
  NO_SEARCH_HISTORY: 'No search history found',
  NO_VIEWED_NOTICES: 'No viewed notices found',
  NO_BOOKMARKS: 'You have not bookmarked any notices yet.',
  NO_MY_NOTICES: 'You have not purchased any notices yet.',
  WELCOME_PROFILE: 'Welcome to your Profile',
  ENTER_EMAIL_OTP: 'Enter OTP sent to your email',
  RATE_US_GOOGLE_STORE: 'Also rate us on Google Play Store',
  RATE_US_APP_STORE: 'Also rate us on',
  RECENT_PURCHASES: 'Your recent purchases',
  VIEW_NOTICE: 'View notice',
  VIEW_ALERT: 'View alert',
  NOTICE_CREDIT_SUCCESS: '1 Credit worth INR {amount} consumed successfully for viewing notice',
  ALERT_CREDIT_SUCCESS: '1 Credit worth INR {amount} consumed successfully for setting alert',
  PAYMENT_SUCCESS: 'Payment Successful!',
  PAYMENT_SUCCESS_TEXT: 'Thank you! your payment of Rs {amount} has been received',
  SWIPE_UP_TO_LOAD_MORE: 'Swipe up to load more',
  ALERT_NOT_SET: 'Alert not set',
  TODAY_NOTICES: 'Notices Added Today',
  TOTAL_NOTICES: 'Notices Available',
  NO_ALERTS: 'No alerts for now',
  IMPORTANT_ALERT: 'Important Alert',
  YOU_HAVE_RECEIVED_ALERT: 'You have received an alert for your property',
  MATCHING_NOTICES: 'notices matching your property.',
  TOTAL_CREDITS_BROUGHT: 'Total Credits Brought',
  NO_TRANSACTIONS: 'You have not purchased anything.',
  DOWNLOADING_FILE: 'Preparing the file...',
  SHARE_FILE: 'Share Downloaded File',
  BOOKMARK_ADDED: 'Bookmark Added.',
  BOOKMARK_REMOVED: 'Bookmark Removed.',
  ADD_PROPERTY_HELP: 'More specific the details about the property, the better will be your alerts',
  HERO_TITLE: 'Protecting your property from litigation issues!',
  HERO_SUBTITLE: 'Track your notices in one click',
  DASHBOARD_HERO_TITLE: 'Searching Notices In',
  POPULAR_LOCATIONS: 'Popular locations',
  READ_NEWS: "Read what's happening in real estate",
  SHOW_NOTICES: 'Showing all purchased notice',
  SHOW_BOOKMARKS: 'Showing all bookmarked notice',
  PROPERTY_FORM_SUCCESS: 'Property updated successfully.',
  NO_IMAGES: 'No Images',
  ALERT_CREDITS_EXPIRY: 'Months From Date of Usage',
  TURN_ON_NOTIFICATION: 'Turn on Notifications',
  SKIP: 'Skip For Now'
};

export const FIELD_LABELS: { [key: string]: string } = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  REGISTER: 'Register',
  CONTINUE: 'Continue',
  NEXT: 'Next',
  VERIFY: 'Verify',
  VERIFY_EMAIL: 'Verify Email',
  CLEAR_FILTERS: 'Clear filters X',
  APPLY: 'Apply',
  SAVE: 'Save',
  SUBMIT: 'Submit',
  VIEW: 'View',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  CITY_DISTRICT: 'City/District',
  TALUKA: 'Taluka',
  VILLAGE: 'Village',
  NOTICE_TYPE: 'Type of Notice',
  PUBLISHED_DATE: 'Publish Date',
  PROPERTY_TYPE: 'Property Type',
  TYPE_OF_PROPERTY: 'Type of Property',
  DETAILS: 'Details',
  STATE: 'State',
  DISTRICT: 'District',
  AREA_NAME: 'Area Name',
  SURVEY_KHASRA_NUMBER: 'Survey/Khasra Number',
  HISSA_NUMBER: 'Hissa Number',
  CTS_NUMBER: 'CTS Number',
  PATWARI_HALKA_NUMBER: 'Patwari Halka Number',
  GAT_NUMBER: 'GAT Number',
  FINAL_PLOT_NUMBER: 'Final Plot Number',
  TP_NUMBER: 'T.P. Number',
  GLR_SURVEY_NUMBER: 'GLR Survey Number',
  PLOT_NUMBER: 'Plot Number',
  UNIT_NUMBER: 'Unit Number',
  BLOCK_NUMBER: 'Block Number',
  ZONE_NUMBER: 'Zone Number',
  WING_NUMBER: 'Wing Number',
  BUILDING_NUMBER: 'Building Number',
  BUILDING_NAME: 'Building Name',
  SOCIETY_NAME: 'Society Name',
  SECTOR_NUMBER: 'Sector Number',
  PROPERTY_NUMBER: 'Property Number',
  OTHER_DETAILS: 'Other Details',
  PERSONAL_INFO: 'Personal Information',
  MY_INTERESTS: 'My Interests',
  MY_TRANSACTIONS: 'My Transactions',
  HELP_CENTER: 'Help Center',
  PHONE_NUMBER: 'Phone Number',
  EMAIL_ID: 'Email ID',
  AGE: 'Age',
  GENDER: 'Gender',
  DATE_OF_BIRTH: 'Date of Birth',
  NAME: 'Your Name',
  ALERT_CREDITS: 'Alert Credits Balance',
  BALANCE: 'Balance',
  VIEW_CREDITS: 'View Credits',
  VIEW_RECEIPT: 'View Receipt',
  DATE_OF_PURCHASE: 'Date of purchase',
  VIEW_DETAIL: 'View Detail',
  PURCHASE_DETAIL: 'Purchase Detail',
  VIEW_ALL: 'View All',
  RECENT_SEARCHES: 'Recent Searches',
  REPORT_CONCERN: 'Report a Concern',
  FEEDBACK: 'Give us feedback',
  ADD_PROPERTY: 'Add Property',
  PROPERTY_ID: 'Property ID',
  ALERT_ON: 'Alert On',
  ALERT_NOTICES: 'Alert Notices',
  TRANSACTION_ID: 'Transaction ID',
  TRANSACTION_TIME: 'Transaction Time',
  TRANSACTION_DATE: 'Transaction Date',
  AMOUNT: 'Amount',
  PAYMENT_METHOD: 'Payment Method',
  NOTICE_VIEW_CREDITS: 'Notice View Credits',
  EXPIRY: 'Expiry',
  ADD_ALERT: 'Add Alert',
  CREATE_ALERT: 'Create Alert',
  SHARE: 'Share',
  BOOKMARK: 'Bookmark',
  REPORT: 'Report',
  SIGNUP_LOGIN: 'Sign Up/Login',
  ENTER_OTP: 'Enter OTP',
  SEARCH: 'Search',
  LOAD_MORE: 'Load More',
  FILTER: 'Filter',
  FILTERS: 'Filters',
  TOTAL_PAYMENT: 'Total Payment',
  MY_PROPERTIES: 'My Properties',
  MY_NOTICES: 'My Notices',
  ALERTS: 'Alerts',
  ADD_INTEREST: 'Add Interest',
  VALIDATE: 'Validate',
  EDIT: 'Edit',
  CORPORATE_OFFICE: 'Corporate Office',
  PHONE: 'Phone',
  LEGAL: 'Legal',
  DOWNLOAD: 'Download',
  IMAGES: 'Images',
  CLEAR_HISTORY: 'Clear History',
  ALERT: 'Alert',
  CONTACT_US: 'Contact Us',
  ABOUT_US: 'About Us',
  FIND_ONLINE: 'Find Us Online',
  SUPPORT: 'Support',
  MY_ACCOUNT: 'My Account',
  ALLOW: 'Allow',
  SEARCH_BY: 'Search By'
};

export const FIELD_PLACEHOLDERS: { [key: string]: string } = {
  FIRST_NAME: 'Enter your first name',
  LAST_NAME: 'Enter your last name',
  NAME: 'Enter your name',
  EMAIL: 'Enter your email address',
  MOBILE: 'Enter your mobile number',
  SEARCH: 'Start typing here to search for a notice',
  TYPE_DETAILS: 'Type your details',
  FINDING: 'Finding...',
  NOTICES_FOUND: 'Notices found',
  OWNER_NAME: 'Please type owner name',
  ADD_IMAGES: 'Add images',
  DATE_OF_BIRTH: 'DOB',
  GENDER: 'Sex',
  SELECT_OPTION: 'Select an option',
  TYPE_AHEAD: 'Type to search',
  GENERIC_TYPE_SELECT: 'Search or select an option',
  GENERIC_INPUT: 'Enter here',
  ADD_IMAGES_HELPER: 'At least 1 image is required',
  CONCERN: 'Enter your concern',
  MANDATORY_FIELDS: '* Indicates Compulsory Fields'
};

export const FIELD_ERRORS = {
  EMAIL_REQUIRED: 'Email is required',
  INVALID_EMAIL: 'Invalid email address',
  FIRST_NAME_REQUIRED: 'First Name is required',
  LAST_NAME_REQUIRED: 'Last Name is required',
  MOBILE_REQUIRED: 'Phone number is required',
  MOBILE_MIN_LENGTH: 'Phone number must be 10 digits',
};

export const PAGE_TITLES = {
  LOGIN: 'Login',
  SIGN_UP: 'Signup',
  LOGOUT: 'Log Out',
  SEARCH_HISTORY: 'My search history',
  LAST_VIEWED_NOTICES: 'My last viewed notices',
  BOOKMARKS: 'My Bookmarks',
  NOTICES: 'My Notices',
  EDIT_PROFILE: 'Edit your details',
  ADD_PROPERTY: 'Add Your Property',
  EDIT_PROPERTY: 'Edit Your Property',
  YOUR_RECEIPTS: 'Your receipt',
  MOST_VIEWED_NOTICES: 'Most viewed notices',
  MOST_BOOKMARKED_NOTICES: 'Most bookmarked notices',
  OTHER_NOTICES: 'Latest redevelopment, debts recovery notices',
  NEWSPAPER_NOTICES: 'Notices from leading newspaper',
  SPOTLIGHTS: 'Spotlights',
  NEWS_ARTICLES: 'News & Articles',
  BUY_SUBSCRIPTION: 'Buy Subscription'
};
