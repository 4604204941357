import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSearchNoticesMutation, checkIfValidApiResponse, Notice } from '@frontend/redux';
import { FIELD_LABELS, STATIC_TEXT } from '@frontend/common';
import { useLazyLoading } from '@frontend/utils-hooks';
import { transformFiltersToStringParams } from '@frontend/utils-helpers';
import { Button, CenterModal } from '@frontend/web-components';

import { SelectedValues } from '../types';
import NoticeList from '../../Notice/List/NoticeList';
import styles from '../Search.module.css';
import FilterPage from './FilterPage';
import PillTags from './PillTags';

// SVG
import { ReactComponent as FilterSVG } from 'assets/src/svgs/filter.svg';

export default function SearchResultScreen() {
  const [params] = useSearchParams();

  // Use `get` method to retrieve parameters by their names
  const searchText = params.get('searchText') || '';
  const propertyType = params.get('propertyType') || undefined;
  const city = params.get('city') || undefined;

  const [searchNotices, { isLoading: isSearchingNotices }] = useSearchNoticesMutation();

  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState<Partial<SelectedValues>>({});
  const [showFilterPage, setShowFilterPage] = useState(false);
  const [searchParams, setSearchParams] = useState<{ [key: string]: string | undefined } | null>(
    null
  );

  const getNotices = async (page: number) => {
    let payload = { query: searchText, page, city, property_type: propertyType };

    if (searchParams && Object.keys(searchParams).length) {
      const transformedFilters = transformFiltersToStringParams(searchParams);
      payload = { ...payload, ...transformedFilters };
    }

    if (Object.keys(filters).length) {
      const transformedFilters = transformFiltersToStringParams(filters);
      payload = { ...payload, ...transformedFilters };
    }

    const res = await checkIfValidApiResponse(searchNotices, payload);
    if (res && res.isSuccess) {
      setErrorMessage('');
      const data = res.data.data;
      return {
        newData: page === 1 ? data : [...notices, ...data],
        newLastPage: res.data.meta.last_page,
      };
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      throw new Error(message);
    }
  };

  const {
    data: notices,
    loading: loadingMore,
    error: loadMoreError,
    loadMore: loadNotices,
    isFirstPage,
    isLastPage,
  } = useLazyLoading(getNotices);

  useEffect(() => {
    const advancedSearchParams: { [key: string]: string | undefined } = {};
    params.forEach((value, key) => {
      if (['searchText'].includes(key)) {
        return;
      }
      advancedSearchParams[key] = value;
    });
    if (Object.keys(advancedSearchParams).length > 0) {
      setSearchParams(advancedSearchParams);
    }
  }, []);

  useEffect(() => {
    loadNotices(true);
  }, [searchText, searchParams, filters]);

  useEffect(() => {
    // Clear filters for new searchText
    clearFilters();
  }, [searchText]);

  useEffect(() => {
    if (loadMoreError) {
      setErrorMessage(loadMoreError);
    }
  }, [loadMoreError]);

  const openFilterPage = () => {
    setShowFilterPage(true);
  };

  const closeFilterPage = () => {
    setShowFilterPage(false);
  };

  const onApplyFilters = (selectedValues: SelectedValues) => {
    setFilters(selectedValues);
    closeFilterPage();
  };

  const clearFilters = () => {
    if (Object.keys(filters).length > 0) {
      setFilters({});
    }
    closeFilterPage();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRemoveFilter = (key: keyof SelectedValues, value: any) => {
    const newFilters: Partial<SelectedValues> = { ...filters };
    const filterValues = newFilters[key];
    if (key === 'published_date') {
      newFilters[key] = [];
    } else {
      newFilters[key] = filterValues
        ? filterValues.filter((filterValue) => filterValue !== value)
        : [];
    }
    setFilters(newFilters);
  };

  const onRemoveAdvancedParam = (key: string) => {
    const newData = { ...searchParams };
    delete newData[key];
    setSearchParams(newData);
  };

  const renderAdvanceFilters = () => {
    return (
      <FilterPage
        searchParams={{
          query: searchText,
          city,
          property_type: propertyType,
        }}
        filters={filters}
        onApplyFilters={onApplyFilters}
        clearFilters={clearFilters}
        closeFilterPage={closeFilterPage}
      />
    );
  };

  return (
    <div className="page-container">
      <div className={styles.titleContainer}>
        <h6>
          {searchText
            ? `${STATIC_TEXT.SHOWING_NOTICES} "${searchText}"`
            : STATIC_TEXT.SHOWING_ALL_NOTICES}
        </h6>
        {!searchParams ||
        Object.keys(searchParams).every((key) => ['city', 'propertyType'].includes(key)) ? (
          <Button variant="outline" size="small" onClick={openFilterPage}>
            <div className="flex items-center">
              <FilterSVG className="mr-2" />
              <h6>{FIELD_LABELS.FILTERS}</h6>
            </div>
          </Button>
        ) : null}
      </div>
      <div className={styles.pillTagContainer}>
        <PillTags
          filters={filters}
          onRemoveFilter={onRemoveFilter}
          searchParams={searchParams}
          onRemoveAdvancedParam={onRemoveAdvancedParam}
        />
      </div>
      <NoticeList
        notices={notices as Notice[]}
        errorMessage={errorMessage}
        loading={isSearchingNotices}
        pagination={true}
        loadMoreNotices={loadNotices}
        loadingMore={loadingMore}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
      />
      <CenterModal
        visible={showFilterPage}
        onClose={closeFilterPage}
        renderContent={renderAdvanceFilters}
      />
    </div>
  );
}
