/* eslint-disable @nx/enforce-module-boundaries */
import { createApi } from '@reduxjs/toolkit/query/react';
import { STATIC_URL_ENDPOINTS, UPDATE_PAYMENT_STATUS, UPDATE_SUBSCRIPTION_STATUS } from '../../types/endpoints';
import { APIResponse, FetchMethods } from '../../types/common';
import {
  CustomPackageRequest,
  PostPaymentStatusRequest,
  AndroidIntentRequest,
  StripeIntentResponse,
  SubscriptionParamsResponse,
  UpdatePaymentStatusRequest,
  CreditStatusResponse,
  RazorpayIntentResponse,
  PriceResponse,
  PostSubscriptionStatusRequest,
  TransactionsResponse,
} from '../../types/subscription';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';
import { AuthActions } from '../../../slices/auth.slice';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    getPaymentParams: builder.mutation<SubscriptionParamsResponse, undefined>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.GET_PAYMENT_PARAMS,
        method: FetchMethods.GET,
      }),
    }),
    postStripeIntent: builder.mutation<StripeIntentResponse, AndroidIntentRequest>({
      query: ({ amount }) => ({
        url: STATIC_URL_ENDPOINTS.POST_STRIPE_INTENT,
        method: FetchMethods.POST,
        body: {
          amount,
        },
      }),
    }),
    postRazorpayIntent: builder.mutation<RazorpayIntentResponse, AndroidIntentRequest>({
      query: ({ amount }) => ({
        url: STATIC_URL_ENDPOINTS.POST_RAZORPAY_INTENT,
        method: FetchMethods.POST,
        body: {
          amount,
        },
      }),
    }),
    postPaymentStatus: builder.mutation<APIResponse, PostPaymentStatusRequest>({
      query: (body) => ({
        url: STATIC_URL_ENDPOINTS.PAYMENT_STATUS,
        method: FetchMethods.POST,
        body: {
          ...body,
        },
      }),
    }),
    putPaymentStatus: builder.mutation<APIResponse, UpdatePaymentStatusRequest>({
      query: ({ paymentId, ...body }) => ({
        url: UPDATE_PAYMENT_STATUS(paymentId),
        method: FetchMethods.PUT,
        body: {
          ...body,
        },
      }),
    }),
    postCustomEnquiry: builder.mutation<APIResponse, CustomPackageRequest>({
      query: (body) => ({
        url: STATIC_URL_ENDPOINTS.CUSTOM_PACKAGE,
        method: FetchMethods.POST,
        body,
      }),
    }),
    getCreditStatus: builder.query<CreditStatusResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.CREDIT_STATUS,
        method: FetchMethods.GET,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(AuthActions.updateCreditStatus(data)); // Dispatch custom action with the data
        } catch (error) {
          // Handle error, if necessary
        }
      },
    }),
    getCreditTransactions: builder.query<TransactionsResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.CREDIT_TRANSACTIONS,
        method: FetchMethods.GET,
      }),
    }),
    getPrices: builder.mutation<PriceResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.PRICES,
        method: FetchMethods.GET,
      }),
    }),
    joinWaitlist: builder.mutation<{ data: APIResponse }, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.WAITLIST,
        method: FetchMethods.GET,
      }),
    }),
    getRzpSubsciptionsPlans: builder.mutation<{ data: APIResponse }, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.RZP_SUBSCRIPTION_PLANS,
        method: FetchMethods.GET,
      }),
    }),
    postRazorpayIntentSubscription: builder.mutation<RazorpayIntentResponse, { planId: string }>({
      query: ({ planId }) => ({
        url: STATIC_URL_ENDPOINTS.PAYMENT_SUBSCRIPTION_RZP_INTENT,
        method: FetchMethods.POST,
        body: {
          plan_id: planId,
        },
      }),
    }),
    postSubscriptionStatus: builder.mutation<APIResponse, PostSubscriptionStatusRequest>({
      query: (body) => ({
        url: STATIC_URL_ENDPOINTS.SUBSCRIPTION_STATUS,
        method: FetchMethods.POST,
        body: {
          ...body,
        },
      }),
    }),
    putSubscriptionStatus: builder.mutation<APIResponse, UpdatePaymentStatusRequest>({
      query: ({ paymentId, ...body }) => ({
        url: UPDATE_SUBSCRIPTION_STATUS(paymentId),
        method: FetchMethods.PUT,
        body: {
          ...body,
        },
      }),
    }),
    cancelRazorpaySubscription: builder.mutation<APIResponse, { planId: string }>({
      query: ({ planId }) => ({
        url: STATIC_URL_ENDPOINTS.RZP_SUBSCRIPTION_CANCEL,
        method: FetchMethods.DELETE,
        body: {
          plan_id: planId,
        },
      }),
    }),
    cancelRevenueCatSubscription: builder.mutation<APIResponse, null>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.REV_SUBSCRIPTION_CANCEL,
        method: FetchMethods.DELETE,
      }),
    }),
  }),
});

export const {
  useGetPaymentParamsMutation,
  usePostStripeIntentMutation,
  usePostPaymentStatusMutation,
  usePutPaymentStatusMutation,
  usePostCustomEnquiryMutation,
  useGetCreditStatusQuery,
  useGetCreditTransactionsQuery,
  usePostRazorpayIntentMutation,
  useGetPricesMutation,
  useJoinWaitlistMutation,
  useGetRzpSubsciptionsPlansMutation,
  usePostRazorpayIntentSubscriptionMutation,
  usePostSubscriptionStatusMutation,
  usePutSubscriptionStatusMutation,
  useCancelRazorpaySubscriptionMutation,
  useCancelRevenueCatSubscriptionMutation
} = subscriptionApi;
