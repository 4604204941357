import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { Spinner, WebSubscriptionContainer, PaymentSuccess } from '@frontend/web-components';
import { SubscriptionStatusName, useGetCreditStatusQuery } from '@frontend/redux';

import { useSubscriptionWeb } from '../../hooks/useSubscriptionWeb';

export function SubscriptionPage() {
  const {
    handlePayment,
    isLoading,
    subscriptionCredits,
    updateStatus: paymentStatus,
    updateMessage,
    prices,
  } = useSubscriptionWeb();
  const { refetch: refetchCreditStatus } = useGetCreditStatusQuery();

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  useEffect(() => {
    if (paymentStatus === SubscriptionStatusName.COMPLETED) {
      setOpenSuccessModal(true);
      refetchCreditStatus();
    } else if (
      [SubscriptionStatusName.FAILED, SubscriptionStatusName.CANCELLED].includes(paymentStatus)
    ) {
      toast.error(updateMessage);
    }
  }, [paymentStatus]);

  if (subscriptionCredits.length === 0) {
    return <Spinner modal />;
  }

  return (
    <div className="page-container">
      <h3>Offer & Subscriptions</h3>
      <WebSubscriptionContainer
        isLoading={isLoading}
        onBuyClick={handlePayment}
        subscriptionCredits={subscriptionCredits}
        prices={prices}
      />
      <PaymentSuccess
        visible={openSuccessModal}
        closeModal={() => setOpenSuccessModal(false)}
        message={updateMessage}
      />
    </div>
  );
}

export default SubscriptionPage;
