/* eslint-disable @typescript-eslint/no-explicit-any */
import FlatList from 'flatlist-react';
import moment from 'moment';

import { FIELD_LABELS, STATIC_TEXT } from '@frontend/common';
import { getReceiptItem } from '@frontend/utils-helpers';

import styles from '../Transactions.module.css';

export default function SubscriptionTransactions({
  transactions,
  creditStatus,
}: {
  creditStatus: any;
  transactions: any;
}) {
  const isAlreadySubscribed = creditStatus?.data.subscription_plan?.payment_status === 'paid';
  const isSubscriptionPending = creditStatus?.data.subscription_plan?.payment_status === 'pending';
  const isSubscriptionCancelled = creditStatus?.data.subscription_plan?.payment_status === 'cancelled';
  const isNotSubscribed = !creditStatus?.data.subscription_plan?.payment_status;
  const cancelDate = creditStatus?.data.subscription_plan?.cancel_date;
  const nextBillingDate = creditStatus?.data.subscription_plan?.next_billing_date;
  const isBillingDateValid =
    nextBillingDate && moment(nextBillingDate, 'DD-MM-YYYY').isSameOrAfter(moment());

  const renderTransactionItem = (item: any) => {
    const consItem = getReceiptItem(item);
    if (!consItem) {
      return null;
    }

    return (
      <div key={item[0].id} className={styles.transactionItemContainer}>
        <div className="flex justify-between mb-2">
          <p className="text-xs font-semibold">{`${
            FIELD_LABELS.DATE_OF_PURCHASE
          }: ${consItem.dateOfPurchase.substring(0, 10)}`}</p>
          <p className="text-sm font-semibold">{`₹ ${consItem.totalPayment}`}</p>
        </div>
        <div className="flex items-center mb-2">
          <p className="text-xs font-semibold">Transaction ID:</p>
          <p className="text-xs ml-2">{consItem.paymentResponse.txn_id}</p>
        </div>
        <div className="flex items-center mb-2">
          <p className="text-xs font-semibold">Transaction Time:</p>
          <p className="text-xs ml-2">{consItem.paymentResponse.txn_time.substring(0, 10)}</p>
        </div>
        <div className="flex items-center mb-2">
          <p className="text-xs font-semibold">Payment Instrument:</p>
          <p className="text-xs ml-2">{consItem.paymentResponse.payment_method}</p>
        </div>
        <div className="mt-2">
          <p className="text-xs font-semibold">
            Membership from {consItem.paymentResponse.subscription_plan?.from.substring(0, 10)} to{' '}
            {consItem.paymentResponse.subscription_plan?.plan_end_at?.substring(0, 10)}
          </p>
        </div>
      </div>
    );
  };

  const renderPlanDetails = () => {
    return (
      <div className={styles.balanceCreditsContainer}>
        <div className="flex items-center">
        <img src={require('assets/src/images/infinite_logo.png')} alt="" />
          <div className="px-3 flex flex-col items-center">
            <p>Infinite Plan</p>
            <span className="text-sm">₹ 99 / Month</span>
          </div>
        </div>
        <div>
          {isNotSubscribed && <p>You are not currently not subscribed.</p>}
          {isAlreadySubscribed && (
            <p>
              Next billing date is{' '}
              {creditStatus.data.subscription_plan?.next_billing_date.substring(0, 10)}
            </p>
          )}
          {isSubscriptionPending && (
            <>
              <div className="text-md font-semibold">Status : Pending</div>
              <div className="text-sm mt-2">
                We're fetching subscription details for your recent payment made. Kindly recheck
                this section post 5-10 mins to see the update.
              </div>
            </>
          )}
          {isSubscriptionCancelled && (
            <>
              <div className="text-md font-semibold">{`Your membership to Infinite Plan stands cancelled as on ${cancelDate}`}</div>
              {isBillingDateValid && <div className="text-sm mt-2">
              {`You can still view unlimited notices till ${nextBillingDate}.`}
              </div>}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="empty-text">{STATIC_TEXT.NO_TRANSACTIONS}</p>
      </div>
    );
  };

  return (
    <div>
      {renderPlanDetails()}
      <h3>Payment History</h3>
      <div className={styles.listContainer}>
        <FlatList
          list={transactions ? Object.values(transactions) : []}
          renderItem={(item) => renderTransactionItem(item)}
          keyExtractor={(item: any) => item[0].id}
          showsVerticalScrollIndicator={false}
          renderWhenEmpty={renderEmptyMessage}
        />
      </div>
    </div>
  );
}
