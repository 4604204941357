import styles from '../Search.module.css';
import { SelectedValues } from '../types';

const PillTags = ({
  filters,
  onRemoveFilter,
  searchParams,
  onRemoveAdvancedParam,
}: {
  filters: any;
  onRemoveAdvancedParam?: (key: string) => void;
  onRemoveFilter: (key: keyof SelectedValues, value: any) => void;
  searchParams?: Record<string, any> | null;
}) => {
  const renderPillTags = Object.keys(filters).flatMap((key, idx) => {
    if (key === 'published_date') {
      const dateRange = filters[key];
      if (dateRange && dateRange.length === 2) {
        return (
          <button
            key={`${key}-${idx}`}
            className={`${styles.valueTag}`}
            onClick={() => onRemoveFilter(key, filters[key])}>
            {`${dateRange[0]} - ${dateRange[1]} x`}
          </button>
        );
      }
      return null;
    } else {
      const obj = filters[key] || [];
      return obj.map((value: any, valueIdx: number) => (
        <button
          key={`${key}-${valueIdx}`}
          className={`${styles.valueTag}`}
          onClick={() => onRemoveFilter(key, value)}>
          {`${value} x`}
        </button>
      ));
    }
  });

  const renderAdvancedSearchPills =
    searchParams &&
    onRemoveAdvancedParam &&
    Object.keys(searchParams).flatMap((key) => {
      const value = searchParams[key];
      return (
        <button
          key={`${key}-${value}`}
          className={`${styles.valueTag}`}
          onClick={() => onRemoveAdvancedParam(key)}>
          {`${value} x`}
        </button>
      );
    });

  return (
    <>
      {renderAdvancedSearchPills}
      {renderPillTags}
    </>
  );
};

export default PillTags;
