import { useEffect, useState } from 'react';

interface CounterProps {
  onValueChange: (value: number) => void;
  value: number;
}

export const CounterComponent = ({ value, onValueChange }: CounterProps) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    onValueChange(internalValue);
  }, [internalValue])

  const handleDecrement = () => {
    if (internalValue > 0) {
      setInternalValue(internalValue - 1);
    }
  };
  const handleIncrement = () => {
    if (internalValue >= 0 && internalValue < 10) {
      setInternalValue(internalValue + 1);
    }
  };
  return (
    <div className='flex flex-col justify-center'>
      <div className="px-2 py-4 text-base font-bold text-blue-500 bg-white rounded-md max-md:px-5">
        <button className="px-2" onClick={handleDecrement}>
          –
        </button>
        <span className="text-blue-500">{internalValue}</span>
        <button className="px-2" onClick={handleIncrement}>
          +
        </button>
      </div>

      {/* <Button onClick={() => onValueChange(internalValue)} disabled={!internalValue || !value}>
        Select Quantity
      </Button> */}
    </div>
  );
};

export default CounterComponent;
