import { FIELD_LABELS, FIELD_PLACEHOLDERS, SearchScreens } from '@frontend/common';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { createQueryParamString } from '@frontend/utils-helpers';
import Button from '../Button/Button';

export default function Searchbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');

  const goToSearch = () => {
    const trimmedQuery = searchText.trim();
    const queryParams = createQueryParamString({ searchText: trimmedQuery });
    const isSearchPage = location.pathname === SearchScreens.SEARCH;
    navigate(`${SearchScreens.SEARCH}?${queryParams}`);
    if (isSearchPage) {
      window.location.reload();
    }
  };

  return (
    <div className="hidden lg:flex items-center w-full px-2 py-1 rounded-md bg-white mx-2">
      <input
        placeholder={FIELD_PLACEHOLDERS.SEARCH}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className="w-full focus:outline-none text-sm"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToSearch();
          }
        }}
      />
      <Button customClasses="m-0" size="small" onClick={goToSearch}>
        {FIELD_LABELS.SEARCH}
      </Button>
    </div>
  );
}
