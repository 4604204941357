import { SubscriptionCredits } from 'redux/src/api/types/subscription';
import CounterComponent from '../SubscriptionCounter/SubscriptionCounter';
import { PriceData } from '@frontend/utils-hooks';

interface WebSubscriptionListProps extends SubscriptionCredits {
  onCounterUpdate: (key: 'alert' | 'notice', value: number) => void;
  prices: PriceData;
}
export const WebSubscriptionListItem = ({
  type,
  amount,
  quantity,
  onCounterUpdate,
  prices,
}: WebSubscriptionListProps) => {
  const isAlertCredit = type.includes('Alert');

  return (
    <div className="flex flex-col p-4 md:p-8 bg-GRAY_BG rounded-2xl w-full justify-between">
      <div className="pb-4">
        <div className="flex items-center">
          <div className="flex bg-black items-center justify-center w-[40px] h-[40px] rounded-full p-2">
            <img
              loading="lazy"
              src={require(`../../../../../../assets/src/images/${
                type.toLocaleLowerCase().includes('alert') ? 'alert-credit' : 'notice'
              }.png`)}
              className="w-[20px]"
              alt="credit"
            />
          </div>

          <p className="ml-2 font-semibold md:text-lg">{type}</p>
        </div>

        <div className="mt-6 text-base leading-6 text-black grow">
          {isAlertCredit
            ? 'Use Alert Credits to setup an alert notification against your added property whenever there is an mention regarding your property in any notice.'
            : 'Use your credits to view any notice from leading newspapers of your interest location. '}
        </div>
      </div>
      
      <div className="flex justify-between items-end h-full">
        <div>
          <div className="text-base text-black">Every credit is</div>
          <div className="mt-4 text-black text-3xl">
            <span>₹</span>
            <span className="font-medium">
              {prices.alertPrices[!quantity ? 1 : quantity].amount}
            </span>
          </div>
        </div>

        <div className="flex flex-col items-end h-full">
          <div className="flex-1"></div> {/* Filler div to push content down */}
          <CounterComponent
            onValueChange={(newQty) =>
              onCounterUpdate(type.toLowerCase().includes('notice') ? 'notice' : 'alert', newQty)
            }
            value={quantity}
          />
        </div>
      </div>
    </div>
  );
};

export default WebSubscriptionListItem;
