import useRazorpay from 'react-razorpay';

import { ColorPalette } from '@frontend/common';
import {
  usePostRazorpayIntentMutation,
  checkIfValidApiResponse,
  RazorpayIntentResponse,
  SubscriptionStatusName,
} from '@frontend/redux';
import { useSubscriptionState, useLoader } from '@frontend/utils-hooks';

const key = process.env.NX_BASE_RAZORPAY_KEY || '';

export const useSubscriptionWeb = () => {
  const [Razorpay] = useRazorpay();
  const { subscriptionCredits, postPaymentUpdate, updateStatus, updateMessage, prices } =
    useSubscriptionState('web');

  const { startLoading, endLoading, isLoading } = useLoader();
  const [getRazorpayIntent] = usePostRazorpayIntentMutation();

  const handlePayment = async ({
    total_alert,
    total_notice,
    amount,
  }: {
    amount: number;
    total_alert: number;
    total_notice: number;
  }) => {
    startLoading();

    const commonStatusBody = {
      total_notice,
      total_alert,
      amount,
      payment_method: 'razorpay',
    };
    const res = await checkIfValidApiResponse(getRazorpayIntent, { amount });
    if (res.data && res.data.data && res.isSuccess) {
      const data = res.data as RazorpayIntentResponse;
      postPaymentUpdate({
        ...commonStatusBody,
        status: SubscriptionStatusName.INITIATED,
      });
      // const order = await createOrder(params);
      const options = {
        key,
        amount: (data.data.amount as unknown as string) || '0',
        order_id: data.data.id || '',
        handler: async (res: {
          razorpay_order_id: string;
          razorpay_payment_id: string;
          razorpay_signature: string;
        }) => {
          await postPaymentUpdate({
            ...commonStatusBody,
            status: SubscriptionStatusName.COMPLETED,
            paymentObj: res,
          });
          endLoading(true);
        },
        description: 'Maha eNotice Notice/Alert Credits',
        currency: 'INR',
        name: 'Maha eNotice',
        theme: { color: ColorPalette.PRIMARY },
        modal: {
          ondismiss: async () => {
            await postPaymentUpdate({
              ...commonStatusBody,
              status: SubscriptionStatusName.CANCELLED,
              paymentObj: { reason: 'cancelled' },
            });
            endLoading(false);
          },
        },
      };

      const rzpay = new Razorpay(options);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rzpay.on('payment.failed', async function (response: any) {
        await postPaymentUpdate({
          ...commonStatusBody,
          status: SubscriptionStatusName.CANCELLED,
          paymentObj: response,
        });
        endLoading(false);
      });
      rzpay.open();
    } else {
      endLoading(false);
    }
  };
  return {
    handlePayment,
    isLoading,
    subscriptionCredits,
    updateStatus,
    updateMessage,
    prices
  };
};
