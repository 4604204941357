/* eslint-disable @nx/enforce-module-boundaries */
import { useState } from 'react';

import { SubscriptionCredits } from 'redux/src/api/types/subscription';
import { STATIC_TEXT } from '@frontend/common';
import { getDynamicText } from '@frontend/utils-helpers';
import { PriceData } from '@frontend/utils-hooks';

import Button from '../Button/Button';
import WebSubscriptionListItem from './SubscriptionItem/SubscriptionItem';
import CustomPackage from './CustomPackage/CustomPackage';
import InfinitePlan from './InfinitePlan/Banner';

const { buy, subtotal } = STATIC_TEXT.screens.subscription;

interface AndroidSubscriptionContainerProps {
  isLoading: boolean;
  onBuyClick: (value: { amount: number; total_alert: number; total_notice: number }) => void;
  prices: PriceData;
  subscriptionCredits: SubscriptionCredits[];
}
export const WebSubscriptionContainer = ({
  subscriptionCredits,
  isLoading,
  onBuyClick,
  prices,
}: AndroidSubscriptionContainerProps) => {
  const [counterState, setInternalCounterState] = useState({ notice: 1, alert: 1 });

  const handeCounterUpdate = (key: 'notice' | 'alert', value: number) => {
    setInternalCounterState({
      ...counterState,
      [key]: value,
    });
  };

  const alertPrice =
    Number(counterState.alert ? prices.alertPrices[counterState.alert]?.amount : 0) *
    counterState.alert;

  const alertGst =
    counterState.alert > 0
      ? (Number(counterState.alert ? prices.alertPrices[counterState.alert]?.gst : 0) / 100) *
        alertPrice
      : 0;

  const total = alertPrice + alertGst;

  return (
    <div className="flex flex-col items-center justify-center max-w-4xl mx-auto">
      <div className="mt-4 w-full">
        {subscriptionCredits.map((item, index) => {
          if (item.credit_type_slug !== 'alert') {
            return null;
          }

          return (
            <WebSubscriptionListItem
              {...item}
              amount={item.amount}
              key={index}
              quantity={
                item.type.toLowerCase().includes('notice')
                  ? counterState.notice
                  : counterState.alert
              }
              onCounterUpdate={handeCounterUpdate}
              prices={prices}
            />
          );
        })}
      </div>

      <div className="rounded mt-12 w-full bg-PRIMARY_TINT flex">
        <div className="bg-PRIMARY p-4 flex items-center w-36 rounded-tl rounded-bl">
          <span className="text-white text-lg font-bold">Limited time offer</span>
        </div>
        <div className="flex flex-col px-2 py-4 w-full">
          <span className="font-bold text-PRIMARY text-lg px-5">
            Buy more and save upto 40% on alert credits!
          </span>
          <div className="px-5 w-full my-2">
            <div className="w-full h-[2px] bg-GRAY_BG" />
          </div>
          <span className="px-5">
            2-5 credits:{' '}
            <span className="font-bold">&#8377; {prices.alertPrices[2].amount} per credit</span>, 6-10
            credits: <span className="font-bold">&#8377; {prices.alertPrices[10].amount} per credit</span>
          </span>
        </div>
      </div>

      <div className="rounded border py-4 mt-12 w-full px-4 lg:px-24">
        {counterState.alert > 0 && (
          <div className="flex flex-row  justify-between my-2">
            <span>Alert Credits X {counterState.alert}</span>
            <span>&#8377; {alertPrice}</span>
          </div>
        )}

        <div className="flex flex-row mt-4 justify-between items-center">
          <div>
            <span className=" font-bold text-lg mr-2">
              {getDynamicText({
                text: subtotal,
                replaceArray: [
                  {
                    key: 'total',
                    value: total.toFixed(2).toString(),
                  },
                ],
              })}
            </span><br />
            <span className="text-sm">(Including GST of &#8377; {alertGst.toFixed(2)})</span>
          </div>

          <Button
            onClick={() =>
              onBuyClick({
                total_alert: counterState.alert,
                total_notice: counterState.notice,
                amount: total,
              })
            }
            disabled={(!counterState.alert && !counterState.notice) || isLoading}>
            {buy}
          </Button>
        </div>
      </div>

      <InfinitePlan />

      <div className="fixed bottom-0 z-10 w-screen flex bg-[#EAF2FC] left-0 py-6 px-4 items-center justify-center">
        <div className="md:w-[50%] flex justify-between items-center">
          <span className="text-sm md:text-xl font-medium mr-4">
            {getDynamicText({
              text: subtotal,
              replaceArray: [{ key: 'total', value: total.toFixed(2).toString() }],
            })}
          </span>

          <Button
            onClick={() =>
              onBuyClick({
                total_alert: counterState.alert,
                total_notice: counterState.notice,
                amount: total,
              })
            }
            customClasses="w-40"
            disabled={(!counterState.alert && !counterState.notice) || isLoading}>
            {buy}
          </Button>
        </div>
      </div>
      <CustomPackage />
    </div>
  );
};

export default WebSubscriptionContainer;
