import React, { useState, useCallback, useEffect } from 'react';

import {
  getUseQueryError,
  useGetCreditStatusQuery,
  useGetCreditTransactionsQuery,
} from '@frontend/redux';
import { Spinner } from '@frontend/web-components';

import SubscriptionTransactions from './Subscriptions';
import CreditsTransactions from './Credits';

// Define the types for transactions
interface Transaction {
  [key: string]: any;
  credit_type: string;
}

interface TransactionsByKey {
  [key: string]: Transaction[];
}

const TransactionsList = () => {
  const { data: creditStatus, isLoading: isGettingCreditStatus } = useGetCreditStatusQuery();
  const {
    data: transactions,
    isLoading: isGettingTransactions,
    error,
    refetch: refreshTransactions,
  } = useGetCreditTransactionsQuery();

  useEffect(() => {
    refreshTransactions();
  }, []);

  const [activeTab, setActiveTab] = useState('credits');

  const separateTransactions = () => {
    if (!transactions) {
      return null;
    }

    const alertTransactions: TransactionsByKey = {};
    const noticeTransactions: TransactionsByKey = {};

    for (const [key, items] of Object.entries(transactions.data)) {
      (items as Transaction[]).forEach((transaction: Transaction) => {
        if (transaction.credit_type === 'alert') {
          if (!alertTransactions[key]) {
            alertTransactions[key] = [];
          }
          alertTransactions[key].push(transaction);
        } else if (transaction.credit_type === 'notice-subscription') {
          if (!noticeTransactions[key]) {
            noticeTransactions[key] = [];
          }
          noticeTransactions[key].push(transaction);
        }
      });
    }

    return {
      alertTransactions,
      noticeTransactions,
    };
  };

  const txn = separateTransactions();

  const renderContent = () => {
    switch (activeTab) {
      case 'credits':
        return (
          <CreditsTransactions transactions={txn?.alertTransactions} creditStatus={creditStatus} />
        );
      case 'subscription':
        return (
          <SubscriptionTransactions
            transactions={txn?.noticeTransactions}
            creditStatus={creditStatus}
          />
        );
      default:
        return null;
    }
  };

  if (isGettingCreditStatus || isGettingTransactions) {
    return <Spinner modal />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-red-600">{getUseQueryError(error)}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="flex border-b border-gray-300">
        <h3
          className={`${
            activeTab === 'credits'
              ? 'border-PRIMARY'
              : 'border-transparent text-gray-500'
          } cursor-pointer py-2 px-4 border-b-2`}
          onClick={() => setActiveTab('credits')}>
          Credits
        </h3>
        <h3
          className={`${
            activeTab === 'subscription'
              ? 'border-PRIMARY'
              : 'border-transparent text-gray-500'
          } cursor-pointer py-2 px-4 border-b-2`}
          onClick={() => setActiveTab('subscription')}>
          Subscription
        </h3>
      </div>
      <div className="mt-4">{renderContent()}</div>
    </div>
  );
};

export default TransactionsList;
